import { addDays, format, formatISO, isBefore } from 'date-fns';
import { IEvent, IEventForm, ILoteDetail, ILoteForm } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { EventDetails } from 'services/event/types';
import { v4 } from 'uuid';

class EditEventService {
  private getFormatedEventData(data: IEventForm): any {
    const { ticket, event_dates } = data;
    const endDate = event_dates.reduce((prev, current) => {
      const prevDate = new Date(prev.end_time);
      const currentDate = new Date(current.end_time);

      return isBefore(prevDate, currentDate) ? current : prev;
    });

    const startDate = event_dates.reduce((prev, current) => {
      const prevDate = new Date(prev.start_time);
      const currentDate = new Date(current.start_time);

      return isBefore(prevDate, currentDate) ? prev : current;
    });

    const eventDates = event_dates.map(eventDate => ({
      ...eventDate,
      date: formatISO(eventDate.date),
      end_time: format(new Date(eventDate.end_time), 'HH:mm'),
      start_time: format(new Date(eventDate.start_time), 'HH:mm')
    }));

    const newTicket = ticket.map(item => ({
      ...item,
      is_paid: item.is_paid ? 'on' : '',
      show_on_page: item.show_on_page ? 'on' : '',
      lot: item.lot.map(lot => {
        const lotDateStart = lot.dateStart;
        const lotDateEnd = lot.dateEnd;

        lotDateStart.setSeconds(0);
        lotDateEnd.setSeconds(0);

        return {
          ...lot,
          dateEnd: lotDateEnd,
          dateStart: lotDateStart,
          end_sale: lotDateEnd,
          end_sale_date: lotDateEnd.toLocaleDateString('pt-br'),
          end_sale_time: lotDateEnd.toLocaleTimeString('pt-br'),
          quantity: lot.quantity || 0,
          start_sale_date: lotDateStart.toLocaleDateString('pt-br'),
          start_sale: lotDateStart,
          start_sale_time: lotDateStart.toLocaleTimeString('pt-br'),
          value: item.is_paid ? lot.value?.toString().replace('R$', '').replace('.', ',').trim() : 0
        };
      })
    }));

    return {
      ...data,
      number: data.number ? data.number.toString() : '',
      image_path: data.image_path?.replace(`${process.env.REACT_APP_CDN_URL}`, ''),
      image_base64: data.image_base64?.replace('data:image/png;base64,', ''),
      event_dates: eventDates,
      end_date: format(endDate.date, 'dd/MM/yyyy'),
      end_hour: format(new Date(endDate.end_time), 'HH:mm'),
      ticket: newTicket,
      start_date: format(startDate.date, 'dd/MM/yyyy'),
      start_hour: format(new Date(startDate.start_time), 'HH:mm')
    };
  }

  private converteEventDetailToEventForm(eventData: EventDetails): IEventForm {
    const convertLot = (lotData: ILoteDetail[]): ILoteForm[] => {
      return lotData.map((lot, index) => ({
        ...lot,
        dateEnd: new Date(lot.end_sale),
        dateStart: new Date(lot.start_sale),
        quantity: lot.quantity,
        quantity_sold: lot.quantity_sold,
        value: lot.value,
        end_sale: lot.end_sale,
        start_sale: lot.start_sale,
        identificator: v4(),
        name: lot.name ? lot.name : `Lote ${index + 1}`,
        is_active: lot.is_active === 1 || lot.is_active === null ? 1 : 0
      }));
    };

    const eventDataForm: IEventForm = {
      active_precheckout: eventData.active_precheckout,
      category_id: eventData.category_id,
      category_title: eventData.category_title,
      dateEnd: eventData.end_date_default,
      dateStart: eventData.start_date_default,
      eventId: eventData.id,
      end_date_default: eventData.end_date_default,
      start_date_default: eventData.start_date_default,
      ticket: eventData.tickets.map(item => ({
        ...item,
        buy_limit: item.buy_limit ? item.buy_limit : null,
        is_paid: !!item.is_paid,
        is_unlimited: !!item.is_unlimited,
        is_active: item.is_active === 1 || item.is_active === null ? 1 : 0,
        show_on_page: !!item.show_on_page,
        identificator: v4(),
        lot: convertLot(item.lot)
      })),
      description: eventData.description,
      is_live: eventData.is_live,
      jobs_lot_pending: eventData.jobs_lot_pending,
      title: eventData.title,
      type: eventData.type,
      sale_url: eventData.sale_url,
      undefined_date: eventData.undefined_date,
      image_name: eventData.image_name,
      image_path: `${process.env.REACT_APP_CDN_URL}${eventData.image_path}`,
      image_id: eventData.image_id,
      reserve_time: eventData.reserve_time,
      city: eventData.city,
      state: eventData.state,
      place: eventData.place,
      zip: eventData.zip,
      street: eventData.street,
      stream_url: eventData.stream_url,
      number: eventData.number?.toString(),
      reference: eventData.reference,
      complement: eventData.complement,
      country: eventData.country,
      district: eventData.district,
      event_dates: eventData.event_dates.map(date => {
        const end_time_hour = date.end_time.split(':');
        const start_time_hour = date.start_time.split(':');
        const formatedDate = addDays(date.date, 1);

        return {
          ...date,
          date: formatedDate,
          arrayId: v4(),
          end_time: new Date(date.date.setHours(Number(end_time_hour[0]), Number(end_time_hour[1]))).toString(),
          start_time: new Date(date.date.setHours(Number(start_time_hour[0]), Number(start_time_hour[1]))).toString()
        };
      }),
      helpsupport_email: JSON.parse(eventData.page_config)?.helpsupport_email,
      location: eventData.location,
      id: eventData.id
    };
    return eventDataForm;
  }

  public detail(event_id: string): Rx.Observable<IEventForm> {
    return eventService
      .details<EventDetails>(event_id)
      .pipe(RxOp.map(response => this.converteEventDetailToEventForm(response)));
  }

  public detailJobsLotPending(eventId: string): Rx.Observable<any> {
    return eventService.detailJobsLotPending(eventId);
  }

  public editEvent(data: IEventForm): Rx.Observable<IEvent> {
    const dataFormated = this.getFormatedEventData(data);

    return eventService.editEvent(dataFormated).pipe(RxOp.map(response => response.data[0]));
  }
}

const editEventService = new EditEventService();
export default editEventService;
