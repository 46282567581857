import { cleanString } from 'helpers/functions';
import { validateCpf } from 'helpers/validations';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-zÀ-ú ]*$/, 'Insira apenas letras')
    .min(1, 'Informe seu Nome')
    .required(),
  email: yup.string().min(1, 'Informe seu E-mail').email('Endereço inválido, revise o e-mail inserido').required(),
  document: yup
    .string()
    .min(1, 'Informe seu Documento')
    .test('check document', 'CPF informado é inválido', (value, ctx) => {
      if (ctx?.parent?.is_international_doc) return true;
      return validateCpf(value);
    })
    .required(),
  is_international_doc: yup.boolean(),
  ddi: yup.string().min(1, '').required('').nullable(),
  phone: yup
    .string()
    .min(8, 'Número no mínimo 8 caracteres')
    .test('phone length without mask', 'Código DDD + número telefone', (phone, ctx) => {
      if (ctx?.parent?.is_international_doc) return true;
      if (!phone) return false;
      const cleanedPhone = cleanString(phone);
      return !(cleanedPhone.length < 10);
    })
    .required()
});

export default validationSchema;
